.bg {
    background-image: url('../assets/images/bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100svh;
}
.bg1 {
    background-image: url('../assets/images/bg1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100svh;
}
.bg2 {
    background-image: url('../assets/images/bg2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100svh;
}
.bg3 {
    background-image: url('../assets/images/bg3.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100svh;
}
.bg4 {
    background-image: url('../assets/images/bg4.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100svh;
}
.bg5 {
    background-image: url('../assets/images/bg5.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100svh;
}
.bg6 {
    background-image: url('../assets/images/bg6.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100svh;
}
.cont {
    container-type: inline-size;
}
/* .fontfit8 {
    font-size: 8vw;
}
.fontfit7 {
    font-size: 7vw;
}
.fontfit6 {
    font-size: 6vw;
}
.fontfit5 {
    font-size: 5vw;
}
.fontfit4 {
    font-size: 4vw;
}
.fontfit3-5 {
    font-size: 3.5vw;
}
.fontfit3 {
    font-size: 3vw;
}
.fontfit2-5 {
    font-size: 2.5vw;
}
.fontfit2 {
    font-size: 2vw;
}
.fontfit1 {
    font-size: 1vw;
} */